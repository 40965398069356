import React, { Fragment } from "react"
import styled from "styled-components"
import CssBaseline from '@material-ui/core/CssBaseline';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';

import Nav from '../components/Nav'

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },
  }),
);

const HistoryPage = () => {
  return (
    <Fragment>
      <CssBaseline />
      <Nav />
      <Container>
        <Typography variant="h1" component="h2" gutterBottom>
          Historia
        </Typography>
      </Container>
    </Fragment>
  )
}

export default HistoryPage
